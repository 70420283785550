module.exports.google = {
  api_key: "AIzaSyDpideuf91dtgZMGE4iYBBoBOLbw1rcGwE",
  map: {
    lat: -2.5489,
    lng: 118.0149,
    zoom: 5,
  },
};

module.exports.app = {
  api_timeout: 10000,
  // domain: 'localhost',
  // bugsnag: false,

  // bugsnag_key: '',
  // base_url: "http://localhost:8080",
  base_url: "https://my.matadortracker.com",
  // api_url: "http://localhost:5005",
  api_url: "https://api-my.matadortracker.com",
  // api_url_v31: 'https://api.widyamatador.com/v3.1',
  socket_enabled: true, // enabled this if need to test socket listener from notifier service
  socket_url: "wss://my.matadortracker.com",
  socket_path: "/ws/device/socket.io",
  socket_delay: {
    update_dashboard: 30000,
  },
  satelite_device_type_id: "device_type_id-0000000000003",
  telto_device_type_id: "device_type_id-0000000000002",
};
