<template lang="pug">
transition(name="modal")
  .modal-mask(style="z-index: 10")
    .modal-wrapper
      .modal-dialog(style="width: 460px;" role="document")
        .modal-content
          .modal-body.p-0
            .justify-content-between.py-3.px-4.mt-1
              div(style="display:flex; ")
                h4.h5.m-0.text-dark.font-weight-bold Feedback
                a.bg-white.border-none(
                  href="javascript:;"
                  role="button",
                  data-dismiss="modal",
                  aria-label="Close"
                  @click="onLogout"
                )
                  div(style="margin-left:310px;margin-bottom:10px;")
                    img(:height="14" :width="14" src="@/assets/img/close.png")
            .my-2.px-4
              .pt-0
                b-form-group(label="Which feature do you like? Why?")
                  b-form-textarea(:rows="5" v-model="feature_like" placeholder="lorem ipsum dolor sit amet, consectetur adipiscing elit")
              .pt-3
                b-form-group(label="Which feature need improvement? Why?")
                  b-form-textarea(:rows="5" v-model="feature_improvement" placeholder="lorem ipsum dolor sit amet, consectetur adipiscing elit")
              .pt-3
                b-form-group(label="What do you think about the design? Is it easy to use?" )
                  b-form-textarea(:rows="5" v-model="about_design" placeholder="lorem ipsum dolor sit amet, consectetur adipiscing elit")
            .py-3.px-4.d-flex.justify-content-end.align-items-center
              button.border-0.bg-white.py-1.px-4.text-danger(style="text-transform: none;" @click="onLogout") Cancel
              button.btn(:disabled="!feature_like || !feature_improvement || !about_design" style="text-transform: none;backgroundColor:#1f548b; padding:3px 35px;" @click="onSubmit") Send
</template>

<script>
import { postFeedback } from '../../utils/api';
export default {
  props: [],  
  data(){
    return {
      feature_like: '',
      feature_improvement: '',
      about_design: ''
    }
  },
  methods: {
    onLogout(timeout = 500){
      this.$store.commit("setShowFeedbackModal", false);
      localStorage.removeItem("user_id");
      this.$cookies.remove('token');
      this.$cookies.remove('user_id');
      /* setTimeout(() => window.location.reload(), timeout);  */
    },
    onSubmit(){
      const {
        feature_like,
        feature_improvement,
        about_design
      } = this;
      postFeedback({
        feature_like,
        feature_improvement,
        about_design
      }).then(() => {
        this.onLogout(2000);
        //remove user_id and token when logout account end disable tmeout in function onLogout to look change the localstorage and cookies
        localStorage.removeItem("user_id");
        this.$cookies.remove('token');
        this.$cookies.remove('user_id');
        this.$toast.open('Thanks for your feedback.');
      });
    }
  }
}
</script>