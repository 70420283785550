import produce from "immer";
import { Bar, mixins } from "vue-chartjs";
import { minifyChartAmount } from "../../utils/utilities";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ["options", "stacked", "minimum"],
  mounted() {
    this.renderChart(
      this.chartData,
      produce(this.options || {}, (draft) => {
        draft.scaleBeginAtZero = false;
        draft.scales = {
          yAxes: [
            {
              ticks: {
                min: parseInt((this.minimum || 0) * 0.95),
                callback: function (value) {
                  return minifyChartAmount(value) + " km/h"; // Menambahkan "L" pada label sumbu Y
                },
              },
              scaleLabel: {
                display: true,
                labelString: "Kecepatan", // Label untuk sumbu Y
                fontColor: "#333",     // Warna teks label
                fontSize: 14,          // Ukuran font label
                fontStyle: "bold",     // Gaya teks label
              },
              stacked: this.stacked || false,
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Jam", // Label untuk sumbu X
                fontColor: "#333",    // Warna teks label
                fontSize: 14,         // Ukuran font label
                fontStyle: "bold",    // Gaya teks label
              },
            },
          ],
        };
      })
    );
  },
};
