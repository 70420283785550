import Vue from "vue";
import SquareEditOutline from "vue-material-design-icons/SquareEditOutline.vue";

Vue.component("edit-icon", {
  components: {
    SquareEditOutline
  },
  props: ["click"],
  data() {
    return {};
  },
  template: `<a class='text-secondary' href="javascript:;" role='button' @click="$emit('click')">
    <square-edit-outline class='icon-2x btn-edit'/>
  </a>`
});
