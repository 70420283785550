<template lang="pug">
transition(name="modal")
  .modal-mask(style="z-index: 100000")
    .modal-wrapper
      .modal-dialog(style="width: 500px;max-width: 95%;" role="document")
        .modal-content.rounded-0
          .modal-body.p-3
            .d-flex.justify-content-between.align-items-center.mb-2.pb-1
              h4.h5.m-0.text-dark.font-weight-bold {{ title || 'Confirmation' }}
              a.bg-white.border-none(
                href="javascript:;"
                role="button",
                data-dismiss="modal",
                aria-label="Close"
                @click="$emit('close')"
              )
                div
                  img(:height="14" :width="14" src="@/assets/img/close.png")
            .upload-wrapper.py-5.px-4.bg-light
              .avatar-upload(v-show='!edit')
                .text-center.p-2
                  label(for='avatar')
                    img.rounded-circle(:src="files.length ? files[0].url : placeholder")
                .text-center.p-2
                  p Drop image file 
                    span [ext: .jpg, .png, .jpeg] 
                    p here or :
                  file-upload.btn.btn-primary(
                    extensions='jpg,jpeg,png' 
                    accept='image/png,image/jpeg,image/jpg' 
                    name='avatar' 
                    :drop='!edit' 
                    v-model='files' 
                    @input-filter='inputFilter' 
                    @input-file='inputFile' 
                    ref='upload'
                  )
                    | Select File
              .avatar-edit(v-show='files.length && edit')
                .avatar-edit-image(v-if='files.length')
                  img(ref='editImage' :src='files[0].url')
                .text-center.p-4
                  button.btn.btn-secondary(type='button' @click.prevent='$refs.upload.clear') Cancel
                  button.btn.btn-primary(type='submit' @click.prevent='editSave') Done

</template>

<script>
import Cropper from 'cropperjs'
import FileUpload from 'vue-upload-component'
export default {
  data(){
    return {
      files: [],
      edit: false,
      cropper: true,
    }
  },
  components: {
    FileUpload,
  },
  watch: {
    edit(value) {
      if (value) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return
          }
          let cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: this.ratio,
            viewMode: 1,
          })
          this.cropper = cropper
        })
      } else {
        if (this.cropper) {
          this.cropper.destroy()
          this.cropper = false
        }
      }
    }
  },
  props: ['selected', 'close', 'title', 'placeholder', 'ratio'],
  methods: {
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert('Your choice is not a picture')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },
    editSave() {
      this.edit = false
      let oldFile = this.files[0]
      let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
      let arr = new Uint8Array(binStr.length)
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i)
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type })
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.$emit('close')
        this.$emit('selected', reader.result)
      }
    },
  }
}
</script>

<style>
.upload-wrapper {
  border: 2px dashed #e0e0e0;
}
.upload-wrapper .avatar-upload .rounded-circle {
  width: 200px;
  height: 200px;
}
.upload-wrapper .text-center .btn {
  margin: 0 .5rem
}
.upload-wrapper .avatar-edit-image {
  max-width: 100%
}
.upload-wrapper img {
  max-width: 100%;
}
.upload-wrapper .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}
.upload-wrapper .drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>
