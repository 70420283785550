<template lang="pug">
div(:class="{'border-bottom': !expanded}")
  .d-flex.align-items-center.justify-content-between
    p.m-0 {{ title }}
    a.text-dark.h3.m-0.p-3(href="javascript:;" @click="expanded = !expanded")
      chevron-up(v-if="expanded")
      chevron-down(v-if="!expanded")
  div(v-if="expanded")
    slot
</template>

<script>
import Vue from 'vue';
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
export default {
  components: {
    ChevronDown,
    ChevronUp
  },
  data(){
    return {
      // expanded: Vue.config.devtools
      expanded: false
    }
  },
  props: ['title'],
  methods: {
  },
  mounted(){
    
  }
}
</script>