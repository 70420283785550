<template lang="pug">
.wrapper(v-bind:class="this.$store.state.theme")
  #main-panel.main-panel(v-bind:class="this.$store.state.theme")
    top-navbar(id="top-navbar" v-bind:class="this.$store.state.theme")
    dashboard-content
        // <content-footer></content-footer>
  side-bar
    template(slot='links')
      sidebar-link(
          v-if="auth.role=='guest'"  
          to="/login" name='Home Page' icon='mdi mdi-home-outline')    
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='Superboard') && this.auth.role=='superadmin' "  
          to='/superboard' name='Superboard' icon='mdi mdi-shield-outline')
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='Dashboard')"  
          to='/dashboard' name='Beranda' icon='mdi mdi-home-outline')
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='Monitoring')"
          to='/monitoring' name='Monitoring' icon='mdi mdi-monitor')
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='Alert')"
          to='/alert' name='Alert' :counter="alertCounter" icon='mdi mdi-alert-circle-outline')
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='ReportTelto')"
          to='/reporttelto' name='Laporan' :counter="alertCounter" icon='mdi mdi-view-list-outline')
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='Track')"
          to='/tracks' name='Riwayat Perjalanan' icon='mdi mdi-call-split')
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='Report')"
          to='/reports' name='Reports' icon='mdi mdi-view-list-outline')
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='Geofence')"
          to='/geofence' name='Geofence' icon='mdi mdi-web')
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='Driver')" 
          to='/drivers' name='Drivers' icon='mdi mdi-lifebuoy')
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='Etalase') && ((this.auth.role== 'guest') || (this.auth.role== 'user'))" 
          to='/Catalogue' name='Rental' icon='mdi mdi-train-car')
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='Rental & Payment') && (this.auth.role== 'superadmin' || this.auth.role== 'admin' || this.auth.role== 'staff' ) " 
          to='/dashboardRental' name='Rental' icon='mdi mdi-train-car')
      //- sidebar-link(
      //-     v-if="this.$store.state.sidebar.find(element => element=='Etalase') || this.auth.role == 'guest' " 
      //-     to='/etalase' name='Rental' icon='mdi mdi-train-car')
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='Vehicle')" 
         to='/vehicles' name='Jumlah Kendaraan' icon='mdi mdi-truck')
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='Device')" 
         to='/devices' name='Perangkat' icon='mdi mdi-cellphone-wireless')
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='User')" 
         to='/users' name='Pengguna' icon='mdi mdi-account-box-outline')
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='Satellite')" 
         to='/vehicle_satellites' name='Satellite' icon='fa fa-wifi')
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='Genset')" 
         to='/generators' name='Generator' icon='fa fa-bolt')
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='TrackerV2')" 
         to='/trackerV2' name='Tracker V2' icon='fa fa-microchip')
      sidebar-link(
          v-if="this.$store.state.sidebar.find(element => element=='User Privelage')" 
         to='/privilage' name='User Privilege' icon='mdi mdi-account-clock-outline')
      sidebar-link(
          to='/setting' name='Pengaturan' icon='mdi mdi-cogs')

    mobile-menu
      li.nav-item
        a.nav-link
          i.ti-panel
          p Stats
      drop-down.nav-item(title='5 Notifications' title-classes='nav-link' icon='ti-bell')
        a.dropdown-item Notification 1
        a.dropdown-item Notification 2
        a.dropdown-item Notification 3
        a.dropdown-item Notification 4
        a.dropdown-item Another notification
      li.nav-item
        a.nav-link
          i.ti-settings
          p Settings
      li.divider
  upload-modal(
    v-if='showAvatarModal' 
    title='Change Profile Picture' 
    :placeholder="require('@/assets/img/avatar.png')" 
    :ratio='1' 
    @selected='onSelectAvatar' 
    @close='toggleAvatarModal'
  )
  //- feedback-modal(v-if="showFeedbackModal")
  logout-modal(v-if="showLogoutModal")
  register-modal(v-if="showRegisterModal"
   title='' 
    @close='toggleRegisterModal')
  verification-modal(v-if="showVerificationModal"
   title='Verify to Matador' 
    @close='toggleVerificationModal')
  back-to-etalase-modal(v-if="showBackToEtalaseModal"
   title='Back to Etalase' 
    @close='toggleVerificationModal')    
  interactive-alert-modal
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import UploadModal from "../../components/Matador/UploadModal.vue";
/* import FeedbackModal from "../../components/Matador/FeedbackModal.vue"; */
import LogoutModal from "../../components/Matador/LogoutModal.vue";
import VerificationModal from "../../components/Matador/Register/VerificationModal.vue";
import BackToEtalaseModal from "../../components/Matador/Register/BackToEtalaseModal.vue";
import RegisterModal from "../../components/Matador/Register/RegisterModal.vue";
import InteractiveAlertModal from "../../components/Matador/InteractiveAlertModal.vue";
import {
  getCurrentUser,
  postAvatar,
  getAlertCounter,
  BASE_URL,
} from "@/utils/api";
import { getUserDetail, postEmailVerification } from "../../utils/api";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      currentTheme: localStorage.getItem("theme-color"),
    };
  },
  components: {
    BackToEtalaseModal,
    RegisterModal,
    VerificationModal,
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UploadModal,

    /*  FeedbackModal, */
    LogoutModal,
    InteractiveAlertModal,
  },
  computed: {
    ...mapGetters({
      auth: "gettersAuth",
    }),
    showAvatarModal() {
      return this.$store.state.showAvatarModal;
    },
    showBackToEtalaseModal() {
      return this.$store.state.showBackToEtalaseModal;
    },
    showFeedbackModal() {
      return this.$store.state.showFeedbackModal;
    },
    showLogoutModal() {
      return this.$store.state.showLogoutModal;
    },
    showRegisterModal() {
      return this.$store.state.showRegisterModal;
    },
    showVerificationModal() {
      return this.$store.state.showVerificationModal;
    },
    alertCounter() {
      return this.$store.state.alertCounter;
    },
  },
  methods: {
    toggleVerificationModal() {
      this.$store.commit(
        "setShowVerificationModal",
        !this.$store.state.showVerificationModal
      );
    },
    toggleBackToEtalaseModal() {
      this.$store.commit(
        "setShowBackToEtalaseModal",
        !this.$store.state.showBackToEtalaseModal
      );
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    syncUser(repeat = true) {
      if (this.$cookies.get("keepLogin") === "no") {
        this.$cookies.set("token", this.$cookies.get("token"), "1h");
      }
      getCurrentUser().then(({ data: { code, result: userData } }) => {
        if (code == 200) {
          this.$cookies.set("user_id", userData.user_id, "1y");
          if (userData.link) {
            userData.avatar = userData.link.replace(
              /http:\/\/\d+\.\d+\.\d+\.\d+/g,
              BASE_URL
            );
          }
          this.$store.commit("setAuth", userData);
          getUserDetail(userData.user_id).then(({ data: { result, code } }) => {
            if (code === 200) {
              this.$store.commit("setSidebar", result.access);
              console.log(this.$store.state.sidebar);
            }
            this.$store.dispatch("getUserCart", this.auth.user_id);
          });
          // console.log(this.$store.state.auth.sidebar)
          repeat && setTimeout(this.syncUser, 120000);
        } else if (
          this.$route.query.user_id &&
          this.$route.query.verification_code
        ) {
          postEmailVerification({
            user_id: this.$route.query.user_id,
            email_verification_code: this.$route.query.verification_code,
          }).then(({ data: { code, result: userData, messgae } }) => {
            if (code == 200) {
              this.$store.dispatch("haveToLogin", false);
              this.$store.dispatch("setAfterLoginPathAction", "/registration");
              console.log(this.$store.state.afterLoginPath, "show");
              this.$store.commit("setAuth", userData);
              this.$store.commit("setSidebar", userData.access);
              this.$cookies.set(
                "keepLogin",
                this.keepLogin ? "yes" : "no",
                "1y"
              );
              this.$cookies.set(
                "token",
                userData.token,
                this.keepLogin ? "1y" : "1h"
              );
              this.$cookies.set(
                "user_id",
                userData.user_id,
                this.keepLogin ? "1y" : "1h"
              );
              this.$cookies.set(
                "role",
                userData.role,
                this.keepLogin ? "1y" : "1h"
              );
              this.$router.replace(this.afterLoginPath);
            }
          });
        } else {
          this.$store.commit("setAfterLoginPath", this.$route.path);
          console.log(this.$route.name, "here");
          console.log(this.$route.path, "heres");

          this.$router.replace("/login");
        }
      });
    },
    toggleAvatarModal() {
      this.$store.commit(
        "setShowAvatarModal",
        !this.$store.state.showAvatarModal
      );
    },
    toggleRegisterModal() {
      this.$store.commit(
        "setShowRegisterModal",
        !this.$store.state.showRegisterModal
      );
    },

    onSelectAvatar(base64) {
      // console.log(this.$store.state.auth.user_id)
      postAvatar({
        profile_picture: base64,
        user_id: this.$store.state.auth.user_id,
      }).then((response) => {
        // console.log(this.$store.state.auth.user_id)
        this.syncUser(false);
        this.$toast.open("Successfully changed profile photo.");
      });
    },
    detectModal() {
      const modalAppear =
        document.getElementsByClassName("modal-mask").length > 0;
      if (modalAppear) {
        document.getElementsByClassName("main-panel")[0].scrollTo(0, 0);
      }
      document.getElementsByClassName("main-panel")[0].style = modalAppear
        ? "overflow: hidden"
        : "overflow: scroll";
      setTimeout(this.detectModal, 500);
    },
    getAlertCounter() {},
  },
  async mounted() {
    this.syncUser();
    this.getAlertCounter();
    this.$sidebar.displaySidebar(this.$cookies.get("show_sidebar") === "yes");
    // this.detectModal();
  },
};
</script>
