import Vue from "vue";

Vue.component("checkbox", {
  props: ["checked", "switch"],
  data() {
    return {};
  },
  template: `<div class="d-flex align-items-center" style="cursor: pointer;" @click="$emit('switch')">
      <img v-if="!checked" height="24" class="mr-2" src="${require("@/assets/img/unchecked.png")}" />
      <img v-if="checked" height="24" class="mr-2" src="${require("@/assets/img/checked.png")}" />
      <slot></slot>
    </div>`
});
