<template lang="pug">
transition(name="modal")
  .modal-mask
    .modal-wrapper
      .modal-dialog(style="width: 400px;max-width: 95%;" role="document")
        .modal-content.flow-29
          .modal-body.p-0
            .d-flex.justify-content-between.border-bottom.py-3.px-4.mt-1
              h4.h5.m-0.text-dark.font-weight-bold {{ title || 'Confirmation' }}
              a.bg-white.border-none(
                href="javascript:;"
                role="button",
                data-dismiss="modal",
                aria-label="Close"
                @click="$emit('close')"
              )
                div
                  img(:height="14" :width="14" src="@/assets/img/close.png")
            .py-4.my-2.px-4
              slot
            .py-3.px-4.d-flex.justify-content-end.align-items-center
              button.border-0.bg-white.py-1.px-4.text-primary(style="text-transform: none;" @click="$emit('close')") Cancel
              button.btn.btn-danger.py-1(style="text-transform: none;border-radius:5px" @click="$emit('confirm')") {{ confirmText }}
</template>

<script>
export default {
  data(){
    return {
      
    }
  },
  props: ['title', 'confirmText'],  
}
</script>