<template lang="pug">
component.btn.btn-matador(:is='tag' :type='nativeType' :disabled='disabled || loading' @click="$emit('click')" class="text-nowrap" :class="[\
{'btn-round': round},\
{'btn-block': block},\
{'w-100': full},\
{[`btn-${type}`]: type && !outline},\
{[`btn-outline-${type}`]: type && outline},\
{[`btn-${size}`]: size},\
{'btn-link': simple},\
{'btn-icon': iconOnly}\
]")
  .d-flex.align-items-center.justify-content-center
    slot(name='loading')
      i.fa.fa-spinner.fa-spin(v-if='loading')
    slot(name='icon')
      i(v-if='icon' :class='icon')
    slot
</template>
<script>
export default {
  name: 'matador-button',
  props: {
    tag: {
      type: String,
      default: "button"
    },
    round: Boolean,
    full: Boolean,
    icon: String,
    outline: Boolean,
    block: Boolean,
    loading: Boolean,
    disabled: Boolean,
    click: Function,
    type: {
      type: String,
      default: "primary"
    },
    nativeType: {
      type: String,
      default: "button"
    },
    size: {
      type: String,
      default: "sm"
    },
    simple: Boolean,
    iconOnly: Boolean
  }
};
</script>
<style>
</style>
