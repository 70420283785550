import Vue from "vue";

Vue.component("loader", {
  props: ["loading", "size", "text"],
  data() {
    return {};
  },
  template: `<div>
    <div v-if="loading" class="text-center py-5">
      <b-spinner style="width: 2.5rem; height: 2.5rem;" variant="primary" label="Large Spinner"></b-spinner>
      <p class="text-center mb-0 mt-4 text-sm" v-if="text">{{ text }}</p>
    </div>
    <div v-if="!loading">
      <slot></slot>
    </div>
  </div>`
});
