<template>

  <div
    id="sidebar-theme"
    class="sidebar"
    :class="this.$store.state.theme"
    

  >  
    <!--
      :data-background-color="backgroundColor"
      :data-active-color="activeColor"
            Tip 1: you can change the color of the sidebar's background using: data-background-color="white | black | darkblue"
            Tip 2: you can change the color of the active button using the data-active-color="primary | info | success | warning | danger"
        -->
    <!-- -->
    <div class="sidebar-wrapper" id="style-3">
      <div v-if="auth" class="sidebar-top text-center py-2" style="position: relative;padding-right: 30px;">
        <button
          class="navbar-toggler navbar-burger"
          style="position: absolute; left: 0; top: 0"
          type="button"
          @click="toggleSidebar"
          :aria-expanded="$sidebar.showSidebar"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-bar"></span>
          <span class="navbar-toggler-bar"></span>
          <span class="navbar-toggler-bar"></span>
        </button>
        <a @click="toggleAvatarModal" href="javascript:;" class="profile-thumb-wrapper">
          <img v-if="auth.role!='guest' && auth.avatar" width="84" height="84" class="my-4 rounded-circle" :src="auth.avatar | assets" alt="" />
          <img v-if="auth.role!='guest' && !auth.avatar" width="84" height="84" class="my-4 rounded-circle" :src="require('@/assets/img/avatar.png')" alt="" />
          <!-- <img v-if="1" width="84" height="84" class="my-4 rounded-circle" :src="require('@/assets/img/avatar.png')" alt="" /> -->
          <span>Change</span>
        </a>
        <a class="profile-thumb-wrapper">
          <img v-if="auth.role=='guest'" width="84" height="84" class="my-4 rounded-circle" :src="require('@/assets/img/avatar.png')" alt="" />
        </a>        
        <h2 class="mt-0 mb-2 h4 font-weight-normal matador-text-02">
          {{ auth.role!='guest' ? auth.name : "Hi, Guest" }}
        </h2>
        <h3 v-if="auth.role!='guest'"
          class="mt-0 mb-2 font-weight-normal matador-text-02"
          style="font-size: 1.1rem"
        >
          {{ auth ? auth.position : "" }}
        </h3>
        <p v-if="auth.role!='guest'" class="matador-text-02">{{ auth ? auth.email : "" }}</p>
        <a v-if="auth.role!='guest'" @click="logout" class="btn btn-sm" :class="{'btn-secondary': !isImpersonateSession, 'btn-warning': isImpersonateSession}" style="padding:3px 20px" href="javascript:;">
          {{ isImpersonateSession ? 'Back to Superadmin' : 'Logout' }}
        </a>
        <p></p>
        <a v-if="auth.role=='guest'" @click="register" class="btn btn-sm btn-secondary" style="padding:5px 40px" href="javascript:;">Sign Up</a>

      </div>
      <div class="hidden-scrollbar">
        <div class="inner">
          <ul class="nav" style="padding:0px !important">
            <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
            <slot  name="links">
              <sidebar-link
                v-for="(link, index) in sidebarLinks"
                :key="index"
                :to="link.path"
                :name="link.name"
                :icon="link.icon"
              >
              </sidebar-link>
            </slot>
          </ul>
          <!-- <moving-arrow :move-y="arrowMovePx"></moving-arrow>  -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MovingArrow from "./MovingArrow.vue"; 
import SidebarLink from "./SidebarLink";

export default {
  props: {
    title: {
      type: String,
      default: "Paper Dashboard",
    },
    backgroundColor: {
      type: String,
      default: "primary",
      validator: (value) => {
        let acceptedValues = ["primary", "black", "darkblue"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    activeColor: {
      type: String,
      default: "primary",
      validator: (value) => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
      addLink: this.addLink,
      removeLink: this.removeLink,
    };
  },
  components: {
    MovingArrow, 
    SidebarLink,
    
  },
  computed: {
    isImpersonateSession(){
      return Boolean((this.$cookies.get('original_session') || {}).token);
    },
    /**
     * Styles to animate the arrow near the current active sidebar link
     * @returns {{transform: string}}
     */
    auth() {
      return this.$store.state.auth;
    },
    arrowMovePx() {
      if(this.activeLinkIndex > 6){
        this.activeLinkIndex=1;
        let res=this.linkHeight * this.activeLinkIndex;
        this.activeLinkIndex=this.activeLinkIndex + 1;
        return res;
      }else{
        return this.linkHeight * this.activeLinkIndex;
      }
    },
  },
  data() {
    return {
      linkHeight:50,
      activeLinkIndex: 0,
      windowWidth: 0,
      isWindows: false,
      hasAutoHeight: false,
      links: [],
    };
  },
  methods: {
    method(){
    },
    register(){
      this.toggleRegisterModal();
    },
    logout(){
      // setTimeout(() => window.location.reload(), 500);
      // Vue.$cookies.remove('token');
      this.toggleLogoutModal();
    },
    findActiveLink() {
      this.links.forEach((link, index) => {
        if (link.isActive()) {
          this.activeLinkIndex = index;
        }
      });
    },
    addLink(link) {
      const index = this.$slots.links.indexOf(link.$vnode);
      this.links.splice(index, 0, link);
    },
    removeLink(link) {
      const index = this.links.indexOf(link);
      if (index > -1) {
        this.links.splice(index, 1);
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleAvatarModal() {
      this.$store.commit("setShowAvatarModal", !this.$store.state.showAvatarModal);
    },
    toggleFeedbackModal() {
      this.$store.commit("setShowFeedbackModal", !this.$store.state.showFeedbackModal);
    },
    toggleLogoutModal() {
  
      this.$store.commit("setShowLogoutModal",!this.$store.state.showLogoutModal);
    },
    toggleRegisterModal() {
  
      this.$store.commit("setShowRegisterModal",!this.$store.state.showRegisterModal);
    },    
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
  },
  mounted() {
    this.$watch("$route", this.findActiveLink, {
      immediate: true,
    });
  },
};
</script>
<style>


</style>
