<template lang="pug">
div.main-report
  div.rounded-lg(:class="{'p-4 p-md-5 border': !isPdf}")
    loader(:loading="loading || !report")
      div(v-if="!!report")
        .mb-5.d-flex
          img(height="52" class="mr-auto" src="@/assets/img/matador.png")
          div
            p.m-0.text-secondary Device Name
            h3.m-0 {{ report.device_name }}
        p.d-flex
          span.text-secondary(style="width: 100px") Start Date
          | : 
          strong.ml-2 {{ dates[0] }}
        p.d-flex
          span.text-secondary(style="width: 100px") End Date
          | : 
          strong.ml-2 {{ dates[1] }}
        section.pt-5
          heading-v1(
            text="Total Rental Data"
            size="sm"
          )
          .row
            .col-auto(v-for="(item, index) in [parseInt(report.total_rental_time), report.total_plowing_area, report.total_fee]")
              div.pr-4.pr-md-5
                p.mb-2.mt-0.text-secondary(style="font-size: .9rem") {{ ['Total Rental Time', 'Total Plowing Area', 'Total Rental Fee'][index] }}
                h3.m-0.font-weight-bold.text-dark {{ item.toString().replace(/\D|\./g,'') }} {{ ['Hours', 'Km', 'USD'][index] }}
        section.pt-5
          heading-v1(
            text="Vehicle Information"
            size="sm"
          )
          .row.pt-3
            .col-12.col-md-6(v-for="(item, index) in [report.vehicle_type, report.tire_count, report.organization_name, report.year, report.vin, report.wiper_width, report.series, report.network_provider, report.engine, report.sim_number, report.torque]")
              .d-flex.pt-2.pb-1
                span.text-secondary(style="width: 160px") {{ ['Vehicle Type', 'Tire Count', 'Origanization Name', 'Year', 'VIN', 'Wiper Width', 'Series', 'Network Provider', 'Engine', 'SIM Number', 'Torque'][index] }}
                | : 
                strong.ml-2 {{ item }}
</template>

<script>
import Vue from 'vue';
import { getReportData } from '../../../utils/api'
Vue.component("main-report-card", {
  props: ["title", "description"],
  data() {
    return {};
  },
  template: `<div class="col-md-4">
    <div class="card shadow-none text-center py-4 py-md-5" style="background-color: #F5FAFF;border: 2px solid #F0F0F5">
      <p class="mt-0 mb-2 font-weight-bold">{{ title }}</p>
      <p class="m-0 text-secondary">{{ description }}</p>
    </div>
  </div>`
});
export default {
  components: {
    
  },
  data(){
    return {
      loading: false,
      report: null
    }
  },
  watch: {
    reportToken: {
      immediate: true,
      handler: function(newVal){
        this.getReport()
      },
      deep: true
    }
  },
  props: ['device_id', 'dates', 'report-token', 'load', 'is-pdf'],
  methods: {
    getReport(){
      const {
        device_id,
        dates: [
          start_date,
          end_date
        ]
      } = this;
      if(device_id && start_date){
        this.loading = true;
        getReportData({
          report_data: 'main_information',
          device_id,
          start_date,
          end_date
        }).then(({ data: { result } }) => {
          this.loading = false;
          this.report = result;
          this.$emit('load');
        })
      }
    }
  }
}
</script>
<style lang="scss">
.main-report {
  div {
    &.col-auto {
      border-right: 1px solid #e0e0e0;
      padding-top: .5rem;
      padding-bottom: .5rem;
      margin-top: 1.5rem;
    }
  }
}
</style>